//////////
// Find store
//////////

// Fetch stores data from server
var stores = null;
fetchStores();
//fetchStoresDummyData(); // Use stores dummy data

function fetchStores() {
    $.ajax({
        dataType: "json",
        url: "/stores-json",
        headers: {
            'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        },
        success: function (data) {
            stores = data[0].Users;
        }
    });
}


let lat, lng;

// Listen to submit
$('.search-form').submit(getGeo);

// Google maps API
function getGeo(e) {
    var API = 'https://maps.googleapis.com/maps/api/geocode/json';
    var location = $('.search-field').val();

    e.preventDefault();

    if (location !== '') {

        // Empty the list
        $('.stores-list').empty();

        // Call the API
        axios.get(API, {
            params: {
                address: location + ', sverige', // Search only in Sweden
                key: 'AIzaSyAQQdHPrg0ZU6KB8lzK4h-91ARa4KmHLpQ'
            }
        })
            .then(function (response) {
                if (response.data.status != 'ZERO_RESULTS') { // Check if correct type of address
                    var geometry = response.data.results[0].geometry.location;

                    lat = geometry.lat;
                    lng = geometry.lng;

                    // Show the formatted user location
                    $('.formatted-address').html('<i class="fa fa-map-marker"></i> Din position: <strong>' + response.data.results[0].formatted_address + '</strong>');

                    // Reset and show the filters
                    Reset_Filters();
                    $('.search-filter').show(); 

                    // Find the Closest stores to this address
                    find_closest_stores(lat, lng, stores);

                    $('.row-headings').html('' +
                        '<div class="grid__col-sm-2"><b>Dist.</b></div>' +
                        '<div class="grid__col-sm-5"><b>Butik</b></div>' +
                        '<div class="grid__col-sm-5"><b>Adress</b></div>');

                } else {
                    $('.formatted-address').html('<b> Du måste ange en korrekt ort eller postnr </b>');
                    Reset_Filters();
                }

            })

    } else {
        // Empty the list
        $('.stores-list').empty();
        $('.formatted-address').html('<b>Du måste ange ort eller postnr</b>');
        $('.row-headings').empty();
        Reset_Filters();
    }
}




// Search Filters 
let selectedFilters = [];
$('.search-filter li input').on('change', function () {
    let text = $(this).attr('id');


    // check if its exist
    if (selectedFilters.includes(text)) {
        selectedFilters.splice(selectedFilters.indexOf(text), 1);
    } else {
        // push it to the array
        selectedFilters.push(text);
    }

    // Find the results for the selected filters 
    if (selectedFilters.length) {
        Filtered_Results(selectedFilters, stores)
    } else {
        // Reset the search to the initial state
        $('.stores-list').empty();
        find_closest_stores(lat, lng, stores);
    }

});

// Find the results for the selected filters 
function Filtered_Results(passedFilters, passedStores) {

    // return an array with the filtered results
    let results = passedStores.filter(function (store) {
        if (passedFilters.some(function (filter) { return store.BrandNames.includes(filter) })) {
            return store
        }
    });

    // Find the Closest stores to this filtered results
    if (results.length) {
        $('.stores-list').empty();
        find_closest_stores(lat, lng, results);
    } else {
        $('.stores-list').empty();
    }
}

// Reset filters
function Reset_Filters() {
    selectedFilters = [];
    $('.search-filter').hide();
    $('.search-filter input:checked').prop('checked', false);
}

// Find the closest stores
function find_closest_stores(lat1, lon1, passedStores) {
    var pi = Math.PI;
    var R = 6371; //equatorial radius
    var distances = [];
    var closest = -1;

    // Calculate the distance ..
    for (i = 0; i < passedStores.length; i++) {
        var lat2 = passedStores[i].Lat;
        var lon2 = passedStores[i].Lon;

        var chLat = lat2 - lat1;
        var chLon = lon2 - lon1;

        var dLat = chLat * (pi / 180);
        var dLon = chLon * (pi / 180);

        var rLat1 = lat1 * (pi / 180);
        var rLat2 = lat2 * (pi / 180);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rLat1) * Math.cos(rLat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        var km = Math.floor(d * 1.609344); // show it in kilometers

        if (km == 0) { km = 0.5; } // show 0.5 km instead of 0 km

        // Push to the distances array
        distances.push({ ID: i, Dist: km });
    }

    // Sort the distances to the closest ones
    distances.sort(function (a, b) {
        return a.Dist - b.Dist;
    });

    // Show only first 10 of the sorted stores
    distances.splice(10, distances.length + 10);


    // Show the list of the closest stores on the Page
    distances.forEach(function (distance) {
        var distID = distance.ID,
            distKM = distance.Dist,
            store = passedStores[distID],
            emailHiddenClass = "",
            phoneHiddenClass = "",
            wwwHiddenClass = "";
        if (!store.Email) {
            emailHiddenClass = "hidden";
        }
        if (!store.Phone) {
            phoneHiddenClass = "hidden";
        }
        if (!store.Www) {
            wwwHiddenClass = "hidden";
        }

        var completeAddress = store.Address + ', ' + store.PostalCode + ', ' + store.City;

        var storesList = '' +
            '<div class="grid">' +
            '<div class="grid__col-xs-12 grid__col-sm-2">' + distKM + ' km</div>' +
            '<div class="grid__col-xs-12 grid__col-sm-5"><span class="store-name">' + store.Name + '</span></div>' +
            '<div class="grid__col-xs-12 grid__col-sm-5"><a href="https://www.google.se/maps/place/' + completeAddress + '" target="_blank"><span class="address">' + completeAddress + '</span></a>' + 
            '<span class="' + phoneHiddenClass + '">' + store.Phone + '</span>' +
            '<span class="' + emailHiddenClass + '"><a href="mailto:' + store.Email + '">' + store.Email + '</a></span>' +
            '<span class="' + wwwHiddenClass + '"><a href="http://' + store.Www + '" target="_blank">' + store.Www + '</a></span>' +
            '</div>' +
            //'</div>' +
            //'<div class="grid">' +
            //'<div class="grid__col-xs-12">' +
            //'<div class="tags"><b>Varumärken:</b> ' + store.BrandNames.map(function (brand) {
            //    return `<span class="tag"> ${brand}</span>`;
            //}) + '</div>' +
            //'</div>' +
            '</div>';

        $('.stores-list').append(storesList);

    })

}


// Reset the search
$('.search-field').on('keyup', function () {
    if ($(this).val().length > 0) {
        $('.search-form .reset').show();
    } else {
        $('.search-form .reset').hide();
    }
});

// Empty the list
$('.search-form .reset').click(function () {
    $('.stores-list, .formatted-address').empty();
    $('.row-headings').empty();
    $('.search-field').val('').focus();
    $('.search-filter').hide();
    $(this).hide();
});


// Hard coded stores dummy data
function fetchStoresDummyData() {

    stores = [
        {
            "Lat": "58.334866",
            "Lon": "12.679985",
            "Name": "Järnia Grästorp",
            "Address": "Bankgatan 1",
            "CompleteAddress": "",
            "City": "Grästorp",
            "PostalCode": "46730",
            "Id": 38,
            "BrandNames": [
                "Minosharp",
                "Opinel",
                "Pulltex",
                "Riedel",
                "Scanpan",
                "Test"
            ],
        },
        {
            "Lat": "59.398178",
            "Lon": "17.950074",
            "Name": "Saven AB",
            "Address": "Kronborgsgränd 9",
            "CompleteAddress": "",
            "City": "Kista",
            "PostalCode": "16446",
            "Id": 37,
            "BrandNames": [
                "Test"
            ]
        },
        {
            "Lat": "58.428352",
            "Lon": "15.611836",
            "Name": "Profilpartner Swärdh & Björk AB",
            "Address": "Gottorpsgatan 1",
            "CompleteAddress": "",
            "City": "Linköping",
            "PostalCode": "58273",
            "Id": 36,
            "BrandNames": [
                "Opinel",
            ]
        },
        {
            "Lat": "59.208783",
            "Lon": "17.662020",
            "Name": "Pad Print AB",
            "Address": "Klastorpsvägen 34",
            "CompleteAddress": "",
            "City": "Södertälje",
            "PostalCode": "15242",
            "Id": 35,
            "BrandNames": [
            ]
        },
        {
            "Lat": "56.974215",
            "Lon": "16.436357",
            "Name": "Tillingeskolan",
            "Address": "Idrottsvägen 1 ",
            "CompleteAddress": "",
            "City": "Timmernabben",
            "PostalCode": "38052",
            "Id": 34,
            "BrandNames": [
            ]
        },
        {
            "Lat": "59.335202",
            "Lon": "18.034791",
            "Name": "AG 925 Restaurang AB",
            "Address": "Kronobergsgatan 37",
            "CompleteAddress": "",
            "City": "Stockholm",
            "PostalCode": "11233",
            "Id": 33,
            "BrandNames": [
            ]
        },
        {
            "Lat": "56.286352",
            "Lon": "13.948425",
            "Name": "Mårten Tannamagare AB",
            "Address": "Mårten Tannamagare AB",
            "CompleteAddress": "",
            "City": "Hästveda",
            "PostalCode": "28023",
            "Id": 32,
            "BrandNames": [
            ]
        },
        {
            "Lat": "55.430622",
            "Lon": "13.813391",
            "Name": "Michael Sjö",
            "Address": "Kyrkogårdsg 19C",
            "CompleteAddress": "",
            "City": "Ystad",
            "PostalCode": "27131",
            "Id": 31,
            "BrandNames": [
            ]
        }
    ]

}

