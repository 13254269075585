//custom code

//Menu 
$('.menu > li').each(function () {
    var hasDropdown = $(this).has('.mega-menu .menu').length == 0;
    if (hasDropdown) {
        //$(this).find('.megadropdown1 > ul > li').addClass('splitted');
        //$(this).find('.megadropdown1').addClass('small-bg');
        $(this).find('div').removeClass('grid__col-lg-3 grid__col-md-3 grid__col-sm-4 grid__col-xs-12');
        $(this).find('.grid > div').addClass('splitted');
        $(this).find('.mega-menu').addClass('small-bg');
    }
});

//Mobile navigation
$('.menu-mobile > li').each(function (e) {
    var hasDropdown = $(this).has('.menu-mobile__submenu').length != 0;

    if (hasDropdown) {
        $(this).prepend('<div class="btn-mobilemenu"></div>');
    }
});

$('.btn-mobilemenu').click(function (e) {
    $(this).parent().find('> .menu-mobile__submenu').slideToggle();
});

//Mobile search
$('.dropdown-search').click(function () {
    $('.top-micro-search').slideToggle();
});

//Set same height on blocks
// $.fn.setAllToMaxHeight = function () {
//     return this.height(Math.max.apply(this, $.map(this, function (e) {
//         return $(e).height();
//     })));
// };

// $.fn.setAllToMinHeight = function () {
//     return this.height(Math.min.apply(this, $.map(this, function (e) {
//         return $(e).height();
//     })));
// };


// var promotionProductList = $('.promotion-products'),
//     productlist = $('.product-list');

// //Set banners to same height AFTER images AND DOM are loaded...
// setTimeout(function () {
//     promotionProductList.imagesLoaded(function () {
//         promotionProductList.each(function () {
//             $('.product-list__grid-item__image').setAllToMaxHeight();
//         });

//     }); 
// }, 1200);

// function setHeightProductList() {
//     setTimeout(function () {
//         productlist.imagesLoaded(function () {
//             $('.product-list__grid-item__image').setAllToMaxHeight();
//         });
//     }, 2000);
// }

// function setHeightProductListFast() {
//     setTimeout(function () {
//         productlist.imagesLoaded(function () {
//             $('.product-list__grid-item__image').setAllToMaxHeight();
//         });
//     }, 1000);
// }

// setHeightProductList();


